.form-body{
    border: 0px solid yellow;
    position: relative;
    padding: 60px 40px;
    display: flex;
    /*flex-shrink: 2;*/
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.form-logo{
    position: relative;
    border: 0px solid blue;
    width: 196px;
    height: 52px;
    background-image: url('../assets/images/MSCI2019Logo.svg');
    background-repeat: no-repeat;
    background-size: cover;
    align-self: center;
}

.form-name{
    position: relative;
    /*top: 12%; */
    margin: 24px 0px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #0A0A0A;
    border: 0px solid teal;
    align-self: center;
    text-align: center;
}

.form-data{
    position: relative;
    top: 19%;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #323130;
    border: 0px solid teal;
    line-height: 28px;
    align-self: center;
    text-align: center;
}

.textfield{
    width: 100%;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #A19F9D;
}

.login-container {
    border: 0px solid violet;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;

}

.content-box {
    position: absolute;
    border: 0px solid red;
    background: #FFFFFF;
    box-sizing: border-box;
    width: 400px;
}

.footer {
    
    display: flex;
    justify-content: space-between;
    background: #26201F;
    position:fixed;
    width: 100%;
    bottom: 0;
    left:0;
    padding: 8px 0;
    opacity: 0.8;
    z-index: 10;
    color: #cccccc;
    font-family: 'Segoe UI';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.footer .footer-link-container a{
    font-size: 14px;
    line-height: 16px;
    color: #CCCCCC;
    margin: 0 5px;
    text-decoration: none;
}

.termsText{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0A0A0A;
}